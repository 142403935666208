import GATSBY_COMPILED_MDX from "/workspace/src/projects/the-fairmount.mdx";
import React from "react";
import Gallery from "@browniebroke/gatsby-image-gallery";
import Header from "../components/Header";
import Content from "../components/Content";
import MdxProvider from "../components/ui/MdxProvider";
import Footer from "../components/Footer";
import {SEO} from "../components/SEO";
import {getSrc} from "gatsby-plugin-image";
function Project(_ref) {
  var data = _ref.data, children = _ref.children;
  var images = data.allFile.edges.map(function (_ref2) {
    var node = _ref2.node;
    return node.childImageSharp;
  });
  var _data$mdx = data.mdx, body = _data$mdx.body, title = _data$mdx.frontmatter.title;
  return React.createElement(MdxProvider, null, React.createElement(Header, null), React.createElement(Content, {
    light: true
  }, React.createElement("div", {
    className: "mx-auto max-w-7xl py-8 px-4 sm:py-16 sm:px-6 lg:px-8"
  }, React.createElement("div", {
    className: "text-center"
  }, React.createElement("h2", {
    className: "text-base font-semibold uppercase tracking-wide text-primary-600"
  }, "Project"), React.createElement("p", {
    className: "mt-1 font-serif text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl"
  }, title), React.createElement("div", {
    className: "mx-auto mt-5 max-w-6xl text-xl text-gray-500"
  }, children))), React.createElement(Gallery, {
    images: images,
    gutter: "0.35rem"
  })), React.createElement(Footer, null));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Project, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export var Head = function Head(_ref3) {
  var _ref3$data$mdx$frontm = _ref3.data.mdx.frontmatter, title = _ref3$data$mdx$frontm.title, featuredImage = _ref3$data$mdx$frontm.featuredImage;
  return React.createElement(SEO, {
    title: title,
    image: getSrc(featuredImage)
  });
};
var query = "3723648159";
